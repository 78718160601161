import React from "react";
import ProductList from "./components/layout/mobile";
import './App.css'
export default function App() {
  return (
    <div className="App">
      <ProductList />
    </div>
  );
}
