import React from "react";
import "./index.css";
import Mask from "../mask";

const CartProduct = ({ product }) => {
  const truncatedName =
    product.product.length > 45
      ? product.product.substring(0, 45) + "..."
      : product.product;

  // const handleClick = () => {
  //   // Replace with Shopee's custom URL scheme if available
  //   const customUrlScheme = "shopee://";
  //   const fallbackUrl = product.url; // Use the product URL as a fallback

  //   // Attempt to open the Shopee app
  //   window.location.href = customUrlScheme;

  //   // Use a timeout to redirect to the fallback URL if the app is not installed
  //   setTimeout(() => {
  //     window.location.href = fallbackUrl;
  //   }, 2000); // Adjust timeout as needed
  // };

  return (
    <div className="productContainer">
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <div>
          <a
            href={product.url}
            target="_blank"
            alt={product.product}
            rel="noreferrer"
            // onClick={(e) => {
            //   e.preventDefault(); // Prevent default link behavior
            //   handleClick();
            // }}
          >
            <img src={product.img} alt={product.product} />
            <div className="mask" onClick={(e) => e.stopPropagation()}>
              <img src="https://down-vn.img.susercontent.com/file/vn-50009109-edf4470845a7ac7e56c61026f49e78e5" />
            </div>
          </a>
        </div>
        {/* <Mask /> */}
        <p className="productName">{truncatedName}</p>
        <p className="productPrice">{product.price}</p>
      </div>
    </div>
  );
};

export default CartProduct;
