// ProductList.js
import React, { useState, useEffect } from "react";
import db from "../../db/db.json";
import CartProduct from "../cart";
import "./mobile1/mobile1.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => setProducts(db.data), []);

  console.log(products);

  return (
    <div className="productList">
      {products.map((product) => (
        <CartProduct key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
